html {
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas{
  height:100%;
  width:100%;
}

#canvas-container{
  height: 300px;
  clip-path: polygon(0 0,100% 0, 100% 65%, 0 100%);
}

a{
  text-decoration: none;
  color:black
}
a:hover{
  color: gray;
}

#root{
  position:absolute;
  top:0px;

}