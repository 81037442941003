.App {
  height: 100vh;
  width: 100vw;
  margin: 0;
  text-align: center;
  display: grid;
  grid-template-rows: 50px 50px 1fr 30px;
  grid-template-areas: "header" "nav" "main" "footer";
}

.App-link {
  color: #61dafb;
}

header{
  grid-area: "header";
}

header h1{
  margin:0 0 0 5px;
  text-align: left;
  font-size: max(min(10vw,60px),40px);
}

h1{
  font-size: 50px;
}

nav{
  grid-area:"nav";
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}

main{
  grid-area: "main";
}

footer{
  grid-area: "footer";
}

footer p{
  margin: 0;
  font-size:xx-small;
}

.NavBar{
  grid-area: "nav";
}

.buttonDiv{
  padding: 5px;
}

/*  Some code heavily modified from */
/* https://codepen.io/swards/pen/gxQmbj */

.chat-container{
  display: flex;
  justify-content: flex-end;
}

.chat-container{
  display: flex;
  align-items: center;
}

.chat {
  width: min(550px,100%);
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0;
  align-items: flex-end;
  justify-content: flex-end;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 3px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 1.4rem;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: #1982fc;
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background:#1982fc;
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.message>h1{
  font-size: 8vw;
}

/* End of chat code */

.img-container{
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.headshot{
  display: none;
  width:0;
}

.headshot-landing{
  width: min(55vw,310px);
  
}

.contactRef{
  align-self: center;
  font-size: larger;
}

.logoPic{
  display: flex;
  justify-content: center;
  align-items:center;
}

.contact-img
{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: 125px;
}

#contacts{
  width: 100%;
}

#leftSideContact{
  display: grid;
  grid-auto-rows: 125px;
  
}

#rightSide{
  display: none;
  margin-top: 70px;
}

#cw {
  position: fixed;
  z-index: -1;
}

@media only screen and (min-width: 430px) {
  .App {
    grid-template-rows: 75px 50px 1fr 30px;
  }
}

@media only screen and (min-width: 800px) {
  .headshot, .headshot-landing{
    display: block;
    width: 400px;
    float:right;
    align-items: flex-end;
  }

  .chat-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
  }

  #leftSideContact{
    grid-template-columns: 1fr 3fr;
    float:left;
    gap: 40px;
    margin: 5% 10% 0 10%;
    text-align: left;
  }

  .message>h1{
    font-size: XXX-large;
  }
}

@media only screen and (min-width: 1100px) {
  #contacts{
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-template-areas: "left" "right";
  }

  #rightSide{
    display: block;
    max-width: 100%;
  }

  .message>h1{
    font-size: 64px;
  }

  .img-container{
    display:flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
}